import React from "react";

import Img from "gatsby-image";
import "./index.css";
import "../style/font-style.css";
import Box from "@material-ui/core/Box";
import AOS from "aos";
import "aos/dist/aos.css";
import { useStaticQuery, graphql, Link } from "gatsby";
import Head from "../components/head";
import Header from "../components/Header_normal";
import RecentNews from "../components/RecentNews";
import Profile from "../components/Profile";
import Media from "../components/Media";
import Content from "../components/content";
import Footer from "../components/footer";
import Pastevent from "../components/pastevent";
import { Hidden } from "@material-ui/core";

export default function Home() {
  if (typeof document !== `undefined`) {
    AOS.init({
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      delay: 600, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false,
      anchorPlacement: "bottom-bottom",
    });
  }
  const data = useStaticQuery(graphql`
    query {
      pc_top: file(relativePath: { eq: "pc_top.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sp_top: file(relativePath: { eq: "sp_top.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cta: file(relativePath: { eq: "cta_contact.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Head />
      <Box width="100%" bgcolor="#E5E5E5">
        <Header />
        <Box
          className="fv"
          width="90%"
          maxWidth="1200px"
          mx={"auto"}
          py="32px"
          position="relative"
        >
          <Hidden xsDown>
            <Img
              style={{ width: "90%", margin: "auto" }}
              fluid={data.pc_top.childImageSharp.fluid}
            />
          </Hidden>
          <Hidden smUp>
            <Img
              style={{ width: "90%", margin: "auto" }}
              fluid={data.sp_top.childImageSharp.fluid}
            />
          </Hidden>
        </Box>
        <Profile />
        <Media id="media" />
        <Box id="news" />
        {/* <Content /> */}
        <Pastevent />
        <Box my={[5, 5, 15, 15, 15]}>
          <Link to="/contacts">
            <Img
              fluid={data.cta.childImageSharp.fluid}
              style={{ width: "50%", margin: "auto", maxWidth: "350px" }}
            />
          </Link>
        </Box>
        <Footer />
      </Box>
    </>
  );
}
